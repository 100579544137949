import React from "react";
import DictEquipmentService from "../../../services/dict/DictEquipmentService";
import { withTranslation } from "react-i18next";

type Props = {
    item: string;
    onDeleteEquipment: (equipmentId: string) => void;
    onEditEquipment: (equipmentId: string, dict: string) => void; // Dodaj callback do edycji
    dict: string; // Dodaj prop `dict` aby przekazać typ słownika
    t: any;
};

const Options: React.FC<Props> = ({ item, onDeleteEquipment, onEditEquipment, dict, t }) => {
    const deleteEquipment = (itemId: string) => {
        DictEquipmentService.deleteDictEquipment(itemId)
            .then(() => {
                onDeleteEquipment(itemId);
            })
            .catch(error => {
                console.error('Error deleting equipment:', error);
            });
    }

    return (
        <div>
            <label htmlFor={t("buttons.delete")} className="icon-delete">
                <img src="/images/navigation/bin.png" onClick={() => deleteEquipment(item)} />
            </label>

            <label htmlFor={t("buttons.edit")} className="icon-edit">
                <img src="/images/navigation/edit.png" onClick={() => onEditEquipment(item, dict)} />
            </label>
        </div>
    );
}

export default withTranslation("global")(Options);
