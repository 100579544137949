import React, { useEffect, useState } from 'react';
import Modal from '../../../constants/Modal';
import { LANGUAGES } from '../../../translations/Languages';
import DictUnitsService from '../../../services/dict/DictUnitsService';
import EditableCell from '../../../common/EditableCell';
import { useTranslation } from 'react-i18next';

interface LanguageModalProps {
    show: boolean;
    onClose: () => void;
    unitId: string; 
}

const LanguageModal: React.FC<LanguageModalProps> = ({ show, onClose, unitId }) => {
    const [unitData, setUnitData] = useState<any | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (unitId) {
            DictUnitsService.getDictUnitDetails(unitId)
                .then(response => {
                    setUnitData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching unit details:', error);
                });
        }
    }, [unitId]);

    const handleSave = (languageCode: string, newName: string, newShortcut: string) => {
        if (unitData) {
            const updatedData = {
                ...unitData,
                name: {
                    ...unitData.name,
                    [languageCode]: newName,
                },
                shortcut: {
                    ...unitData.shortcut,
                    [languageCode]: newShortcut,
                }
            };
            setUnitData(updatedData);
        }
    };

    const handleModalClose = () => {
        if (unitData) {
            DictUnitsService.updateDictUnitTranslations(unitData)
                .then(() => {
                    console.log('Unit updated successfully');
                })
                .catch(error => {
                    console.error('Error updating unit:', error);
                });
        }
        onClose();
    };

    return (
        <Modal show={show} onClose={handleModalClose} title="Unit Details">
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Name</th>
                        <th>Shortcut</th>
                    </tr>
                </thead>
                <tbody>
                    {LANGUAGES.map((language) => (
                        <tr key={language.code}>
                            <td>{language.label}</td>
                            <td>
                                <EditableCell
                                    value={unitData?.name[language.code] || t('translations.not_set_yet')}
                                    onSave={(newValue) => handleSave(language.code, newValue, unitData?.shortcut[language.code] || '')}
                                />
                            </td>
                            <td>
                                <EditableCell
                                    value={unitData?.shortcut[language.code] || t('translations.not_set_yet')}
                                    onSave={(newValue) => handleSave(language.code, unitData?.name[language.code] || '', newValue)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Modal>
    );
};

export default LanguageModal;
