import React, { useState, useEffect } from "react";
import { DictBodyParam } from "../../../types/types";
import DictBodyParamsService from '../../../services/dict/DictBodyParamsService';
import AddDictBodyParam from "./AddDictBodyParam";
import Options from "./Options";
import LanguageModal from "./LanguageModal";
import { useTranslation } from "react-i18next";

const ShowDictBodyParam: React.FC = () => {
    const [dictBodyParams, setDictBodyParams] = useState<DictBodyParam[]>([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedBodyParamId, setSelectedBodyParamId] = useState<number | null>(null);
    const { t } = useTranslation("global");

    useEffect(() => {
        loadDictBodyParams();
    }, []);

    const loadDictBodyParams = () => {
        DictBodyParamsService.getDictBodyParams()
            .then(response => {
                setDictBodyParams(response.data);
            })
            .catch(error => {
                console.error('Error loading dict body parameters:', error);
            });
    };

    const handleAddBodyParam = (newBodyParam: DictBodyParam) => {
        setDictBodyParams(prevBodyParams => [...prevBodyParams, newBodyParam]);
    };

    const handleDeleteBodyParam = (bodyParamId: number) => {
        setDictBodyParams(prevBodyParams => prevBodyParams.filter(param => param.id !== bodyParamId));
    };

    const handleEditBodyParam = (id: number) => {
        setSelectedBodyParamId(id);
        setModalVisible(true);
    };

    return (
        <div>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>{t("table.name")}</th>
                        <th>{t("table.description")}</th>
                        <th>{t("table.options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {dictBodyParams.map((row) => (
                        <tr key={row.id} style={{ borderBottom: '1px solid #ddd' }}>
                            <td>{row.name}</td>
                            <td>{row.description}</td>
                            <td>
                                <Options item={row.id} onDeleteBodyParam={handleDeleteBodyParam} onEditBodyParam={handleEditBodyParam} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <AddDictBodyParam dictBodyParams={dictBodyParams} onAddBodyParam={handleAddBodyParam} />
            <LanguageModal
                show={modalVisible}
                onClose={() => setModalVisible(false)}
                bodyParamId={selectedBodyParamId!}
            />
        </div>
    );
};

export default ShowDictBodyParam;
