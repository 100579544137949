import React, { Component } from "react";

import UserService from "../../services/user.service";

type Props = {};

type State = {
  content: string;
}

export default class Home extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      content: ""
    };
  }

  componentDidMount() {
    UserService.getPublicContent().then(
      response => {
        this.setState({
          content: response.data
        });
      },
      error => {
        this.setState({
          content:
            (error.response && error.response.data) ||
            error.message ||
            error.toString()
        });
      }
    );
  }

  render() {
    return (
      <div>
        <div className="card">
          <img src="/images/zapiszto.png" alt="general diagram" style={{ width: "100%" }} />
        </div>
        <div className="card">
          <img src="/images/zapiszto - public.png" alt="database diagram" style={{ width: "100%" }} />
        </div>
      </div>
    );
  }
}
