import React, { useEffect, useState } from 'react';
import Modal from '../../../constants/Modal';
import { LANGUAGES } from '../../../translations/Languages';
import DictBodyParamsService from '../../../services/dict/DictBodyParamsService';
import EditableCell from '../../../common/EditableCell';
import { TranslationsDictBodyParamsDto } from '../../../types/types';
import { useTranslation } from 'react-i18next';

interface LanguageModalProps {
    show: boolean;
    onClose: () => void;
    bodyParamId: number; 
}

const LanguageModal: React.FC<LanguageModalProps> = ({ show, onClose, bodyParamId }) => {
    const [bodyParamData, setBodyParamData] = useState<TranslationsDictBodyParamsDto | null>(null);
    const { t } = useTranslation();

    useEffect(() => {
        if (bodyParamId) {
            DictBodyParamsService.getDictBodyParamDetails(bodyParamId)
                .then(response => {
                    setBodyParamData(response.data);
                })
                .catch(error => {
                    console.error('Error fetching body parameter details:', error);
                });
        }
    }, [bodyParamId]);

    const handleSave = (languageCode: string, newName: string, newDescription: string) => {
        if (bodyParamData) {
            const updatedData = {
                ...bodyParamData,
                name: {
                    ...bodyParamData.name,
                    [languageCode]: newName,
                },
                description: {
                    ...bodyParamData.description,
                    [languageCode]: newDescription,
                }
            };
            setBodyParamData(updatedData);
        }
    };

    const handleModalClose = () => {
        if (bodyParamData) {
            DictBodyParamsService.updateDictBodyParamTranslations(bodyParamData)
                .then(() => {
                    console.log('Body parameter updated successfully');
                })
                .catch(error => {
                    console.error('Error updating body parameter:', error);
                });
        }
        onClose();
    };

    return (
        <Modal show={show} onClose={handleModalClose} title="Body Parameter Details">
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>Language</th>
                        <th>Name</th>
                        <th>Description</th>
                    </tr>
                </thead>
                <tbody>
                    {LANGUAGES.map((language) => (
                        <tr key={language.code}>
                            <td>{language.label}</td>
                            <td>
                                <EditableCell
                                    value={bodyParamData?.name[language.code] || t('translations.not_set_yet')}
                                    onSave={(newValue) => handleSave(language.code, newValue, bodyParamData?.description[language.code] || '')}
                                />
                            </td>
                            <td>
                                <EditableCell
                                    value={bodyParamData?.description[language.code] || t('translations.not_set_yet')}
                                    onSave={(newValue) => handleSave(language.code, bodyParamData?.name[language.code] || '', newValue)}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Modal>
    );
};

export default LanguageModal;
