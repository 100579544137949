import React, { useState } from "react";
import { DictCategories, DictExercises, NewDictExercises } from "../../../types/types";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Service from '../../../services/exercises';
import * as Yup from 'yup';
import { withTranslation } from "react-i18next";
import { LANGUAGES } from "../../../translations/Languages";

type Props = {
    dictExercises: DictExercises[];
    dictCategories: DictCategories[];
    onAddExercise: (newExercise: DictExercises) => void;
    t: any;
};

const AddDictExercise: React.FC<Props> = ({ dictExercises, dictCategories, onAddExercise, t }) => {
    const [selectedLanguage, setSelectedLanguage] = useState(LANGUAGES[0].code);

    const postDictExercises = (values: { name: string; categoryId: string }) => {
        const isNameExisting = dictExercises.some(exercise => exercise.name === values.name);
        if (!isNameExisting) {
            const newDictExerciseData: NewDictExercises = { 
                id: crypto.randomUUID(),
                name: { [selectedLanguage]: values.name },
                categoryId: values.categoryId  
            };
            Service.postDictExercisePerUser(newDictExerciseData)
            .then(() => {
                const newExercise: DictExercises = {
                    id: newDictExerciseData.id,
                    name: values.name,
                    category_name: dictCategories.find((category: DictCategories) => category.id === values.categoryId)?.name || '',
                    category_id: values.categoryId,  
                    dict: "PER_USER",
                    dict_id: newDictExerciseData.id, 
                    category_type: 'PER_USER'
                };
                onAddExercise(newExercise);
            })
            .catch(error => {
                console.error('Błąd podczas wysyłania zapytania:', error);
            });
        } else {
            console.log("Nazwa już istnieje:", values.name);
        }
    };

    return (
        <div>
            <Formik
                initialValues={{ name: '', categoryId: '' }}
                validationSchema={Yup.object({
                    name: Yup.string()
                        .required(t('validation.this_field_is_required'))
                        .notOneOf(dictExercises.map(exercise => exercise.name), t('validation.unique')),
                    categoryId: Yup.string().required(t('validation.this_field_is_required'))
                })}
                onSubmit={(values, { setSubmitting }) => {
                    postDictExercises(values);
                    setSubmitting(false);
                }}
            >
                {formik => (
                    <Form>
                        <div className="form-container">
                            <div className="form-field-container">
                                <label htmlFor="language">
                                    <select
                                        id="language"
                                        value={selectedLanguage}
                                        onChange={(e) => setSelectedLanguage(e.target.value)}
                                        className="select-field"
                                    >
                                        {LANGUAGES.map(lang => (
                                            <option key={lang.code} value={lang.code}>
                                                {lang.label}
                                            </option>
                                        ))}
                                    </select>
                                </label>
                            </div>

                            <div className="form-field-container">
                                <ErrorMessage name="name" component="div" className="error" />
                                <Field name="name" type="text" className="form-field" placeholder={t('table.name')} />
                            </div>

                            <div className="form-field-container">
                                <ErrorMessage name="categoryId" component="div" className="error" />
                                <Field as="select" name="categoryId" className="select-field">
                                    <option value="" disabled>{t("table.category")}</option>
                                    {dictCategories.map((category: DictCategories) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}
                                </Field>
                            </div>

                            <button type="submit" disabled={formik.isSubmitting} className="submit-button">{t("buttons.add")}</button>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default withTranslation("global")(AddDictExercise);
