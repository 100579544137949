import React from "react";
import DictExercisesService from "../../../services/dict/DictExercisesService";
import { withTranslation } from "react-i18next";

type Props = {
    item: string;
    dict: string;
    onDeleteExercise: (id: string) => void;
    onEditExercise: (exerciseId: string, dict: string) => void;
    t: any;
};

const Options: React.FC<Props> = ({ item, dict, onDeleteExercise, onEditExercise, t }) => {
    const deleteExercise = (itemId: string) => {
        DictExercisesService.deleteDictExercise(itemId)
            .then(() => {
                onDeleteExercise(itemId);
            })
            .catch(error => {
                console.error('Error deleting exercise:', error);
            });
    };

    return (
        <div>
            <label htmlFor={t("buttons.delete")} className="icon-delete">
                <img src="/images/navigation/bin.png" alt={t("buttons.delete")} onClick={() => deleteExercise(item)} />
            </label>

            <label htmlFor={t("buttons.edit")} className="icon-edit">
                <img src="/images/navigation/edit.png" alt={t("buttons.edit")} onClick={() => onEditExercise(item, dict)} />
            </label>
        </div>
    );
}

export default withTranslation("global")(Options);
