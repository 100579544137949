import React from "react";
import DictQuantityTypeService from "../../../services/dict/DictQuantityTypeService";
import { withTranslation } from "react-i18next";

type Props = {
    item: string;
    onDeleteQuantityType: (quantityTypeId: string) => void;
    onEditQuantityType: (quantityTypeId: string, dict: string) => void;
    dict: string;
    t: any;
};

const Options: React.FC<Props> = ({ item, onDeleteQuantityType, onEditQuantityType, dict, t }) => {
    const deleteQuantityType = (quantityTypeId: string) => {
        DictQuantityTypeService.deleteDictQuantityType(quantityTypeId)
            .then(() => {
                onDeleteQuantityType(quantityTypeId);
            })
            .catch(error => {
                console.error('Error deleting quantity type:', error);
            });
    }

    return (
        <div>
            <label htmlFor={t("buttons.delete")} className="icon-delete">
                <img src="/images/navigation/bin.png" onClick={() => deleteQuantityType(item)} />
            </label>

            <label htmlFor={t("buttons.edit")} className="icon-edit">
                <img src="/images/navigation/edit.png" onClick={() => onEditQuantityType(item, dict)} />
            </label>
        </div>
    );
}

export default withTranslation("global")(Options);
