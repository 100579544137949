import React, { useState, useEffect } from "react";
import { DictQuantityType } from "../../../types/types";
import DictQuantityTypeService from '../../../services/dict/DictQuantityTypeService';
import AddDictQuantityTypePerUser from "./AddDictQuantityType";
import Options from "./Options";
import LanguageModal from "./LanguageMondal";
import { useTranslation } from "react-i18next";

const ShowDictQuantityType: React.FC = () => {
    const [dictQuantityTypes, setDictQuantityTypes] = useState<DictQuantityType[]>([]);
    const [showBasic, setShowBasic] = useState(true);
    const [showPerUser, setShowPerUser] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedQuantityTypeId, setSelectedQuantityTypeId] = useState<string | null>(null);
    const { t } = useTranslation("global");

    useEffect(() => {
        loadDictQuantityTypes();
    }, []);

    const loadDictQuantityTypes = () => {
        DictQuantityTypeService.getDictQuantityTypes()
            .then(response => {
                setDictQuantityTypes(response.data);
            })
            .catch(error => {
                console.error('Error loading dict quantity types:', error);
            });
    };

    const handleAddQuantityType = (newQuantityType: DictQuantityType) => {
        setDictQuantityTypes(prevQuantityTypes => [...prevQuantityTypes, newQuantityType]);
    };

    const handleDeleteQuantityType = (quantityTypeId: string) => {
        setDictQuantityTypes(prevQuantityTypes => prevQuantityTypes.filter(quantityType => quantityType.dict_id !== quantityTypeId));
    };

    const handleShowBasicChange = () => {
        setShowBasic(!showBasic);
    };

    const handleShowPerUserChange = () => {
        setShowPerUser(!showPerUser);
    };

    const handleNameDoubleClick = (id: string, dict: string) => {
        setSelectedQuantityTypeId(id);
        setModalVisible(true);
    };

    const filteredQuantityTypes = dictQuantityTypes.filter(quantityType => {
        if (showBasic && quantityType.dict === "BASIC") return true;
        if (showPerUser && quantityType.dict === "PER_USER") return true;
        return false;
    });

    return (
        <div>
            <label>
                <input
                    type="checkbox"
                    checked={showBasic}
                    onChange={handleShowBasicChange}
                />
                {t('dictionaries.basic')}
            </label>
            <label>
                <input
                    type="checkbox"
                    checked={showPerUser}
                    onChange={handleShowPerUserChange}
                />
                {t('dictionaries.per_user')}
                </label>
            <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    <tr>
                        <th>{t("table.name")}</th>
                        <th>{t("table.shortcut")}</th>
                        <th>{t("table.options")}</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredQuantityTypes.map((row) => (
                        <tr key={row.id} style={{ borderBottom: '1px solid #ddd' }}>
                            <td>{row.name}</td>
                            <td>{row.shortcut}</td>
                            <td>
                                {row.dict === "PER_USER" ? (
                                    <Options item={row.dict_id} dict={row.dict} onDeleteQuantityType={handleDeleteQuantityType} onEditQuantityType={handleNameDoubleClick} />
                                ) : (
                                    t("dictionaries.options_unavailable")
                                )}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
            <AddDictQuantityTypePerUser dictQuantityTypes={dictQuantityTypes} onAddQuantityType={handleAddQuantityType} />
            <LanguageModal
                show={modalVisible}
                onClose={() => setModalVisible(false)}
                quantityTypeId={selectedQuantityTypeId!}
            />
        </div>
    );
};

export default ShowDictQuantityType;
